import React, { useState, useEffect } from 'react';
import './simple.css';

const gravity = 9.8; // Acceleration due to gravity (m/s^2)
const frameRate = 60; // Frames per second
const groundHeight = 450; // Height where the ground is located

function SimpleSimulator() {
  const [densityAir, setDensityAir] = useState(1.225); // Air resistance constant for the ball
 
  // Ball
  const [massBall, setMassBall] = useState(5); // Mass of the ball (kg)
  const [coffecientBall, setCofficientBall] = useState(1.0); // Air resistance constant for the ball
  const [areaBall, setAreaBall] = useState(1.0); // Air resistance constant for the ball
  const [positionBall, setPositionBall] = useState(0); // Position of the ball (in pixels)
  const [velocityBall, setVelocityBall] = useState(0); // Velocity of the ball
  const [isFallingBall, setIsFallingBall] = useState(false); // Whether the ball is falling
  const [dragBall, setDragBall] = useState(0.0); // Air resistance constant for the ball
 
  // Feather
  const [massFeather, setMassFeather] = useState(1); // Mass of the feather (kg)
  const [coffecientFeather, setCoffecientFeather] = useState(1.0); // Air resistance constant for the feather
  const [areaFeather, setAreaFeather] = useState(1.0); // Air resistance constant for the ball
  const [positionFeather, setPositionFeather] = useState(0); // Position of the feather (in pixels)
  const [velocityFeather, setVelocityFeather] = useState(0); // Velocity of the feather
  const [isFallingFeather, setIsFallingFeather] = useState(false); // Whether the feather is falling
  const [dragFeather, setDragFeather] = useState(0.0); // Air resistance constant for the ball
 
  // Function to start the simulation
  const startFalling = () => {
    setIsFallingBall(true);
    setIsFallingFeather(true);
    setPositionBall(0);
    setPositionFeather(0);
    setVelocityBall(0);
    setVelocityFeather(0);
  };

  // Calculate net acceleration for the ball
  const calculateAccelerationBall = (velocity) => {
    const dragForce = 0.5 * coffecientBall * densityAir * areaBall * velocity* velocity; // Air resistance force for the ball
    setDragBall(dragForce) 
    return (gravity - dragForce/massBall);
  };

  // Calculate net acceleration for the feather
  const calculateAccelerationFeather = (velocity) => {
    const dragForce = 0.5 * coffecientFeather * densityAir * areaFeather * velocity* velocity; // Air resistance force for the feather
    setDragFeather(dragForce) 
    return (gravity - dragForce/massFeather);
  };

  // Effect to update the ball's position and velocity
  useEffect(() => {
    if (isFallingBall) {
      const interval = setInterval(() => {
        setVelocityBall((prevVelocity) => {
          const acceleration = calculateAccelerationBall(prevVelocity);
          const newVelocity = prevVelocity + acceleration / frameRate;

          setPositionBall((prevPosition) => {
            const newPosition = prevPosition + newVelocity / frameRate;

            // Stop when the ball reaches the ground
            if (newPosition >= groundHeight) {
              setIsFallingBall(false);
              clearInterval(interval);
              return prevPosition;
            }
            return newPosition;
          });

          return newVelocity;
        });
      }, 1000 / frameRate);

      return () => clearInterval(interval);
    }
  }, [isFallingBall, coffecientBall, massBall]);

  // Effect to update the feather's position and velocity
  useEffect(() => {
    if (isFallingFeather) {
      const interval = setInterval(() => {
        setVelocityFeather((prevVelocity) => {
          const acceleration = calculateAccelerationFeather(prevVelocity);
          const newVelocity = prevVelocity + acceleration / frameRate;

          setPositionFeather((prevPosition) => {
            const newPosition = prevPosition + newVelocity / frameRate;

            // Stop when the feather reaches the ground
            if (newPosition >= groundHeight) {
              setIsFallingFeather(false);
              clearInterval(interval);
              return prevPosition;
            }
            return newPosition;
          });

          return newVelocity;
        });
      }, 1000 / frameRate);

      return () => clearInterval(interval);
    }
  }, [isFallingFeather, coffecientFeather, massFeather]);

  return (
    <div className="Box1">
      <h3>Gravity Simulator: Ball and Feather</h3>
      <div className="ground" />

      {/* Ball */}
      <div
        className="object ball"
        style={{
          transform: `translateY(${positionBall}px)`,
        }}
      >
         <p>
         <span> v:{Math.round(velocityBall * 100)/100} </span> <br/>
         <span> drag: {Math.round(dragBall * 100)/100}</span>
         </p>
      </div>

      {/* Feather */}
      <div
        className="object feather"
        style={{
          transform: `translateY(${positionFeather}px)`,
        }}
      >
        <p>
         <span> v:{Math.round(velocityFeather * 100)/100} </span> <br/>
         <span> drag: {Math.round(dragFeather * 100)/100}</span>
         </p>
      </div>

      <div className="controls">
        <label>
          Air Density (kg/m^3):
          <input
            type="number"
            min="1"
            value={densityAir}
            onChange={(e) => setDensityAir(Number(e.target.value))}
          />
        </label>
        <b>Ball Settings</b>
        <label>
          Mass (kg):
          <input
            type="number"
            min="1"
            value={massBall}
            onChange={(e) => setMassBall(Number(e.target.value))}
          />
        </label>
        <label>
          Coffecient:
          <input
            type="range"
            min="0"
            max="1"
            step="0.1"
            value={coffecientBall}
            onChange={(e) => setCofficientBall(Number(e.target.value))}
          />
          {coffecientBall}
        </label>

        <label>
          Cross-section area:
          <input
            type="range"
            min="0.1"
            max="5"
            step="0.1"
            value={areaBall}
            onChange={(e) => setAreaBall(Number(e.target.value))}
          />
          {areaBall}
        </label>

        <b>Feather Settings</b>
        <label>
          Mass (kg):
          <input
            type="number"
            min="0.001"
            value={massFeather}
            onChange={(e) => setMassFeather(Number(e.target.value))}
          />
        </label>
        <label>
          Coffecient:
          <input
            type="range"
            min="0"
            max="1"
            step="0.1"
            value={coffecientFeather}
            onChange={(e) => setCoffecientFeather(Number(e.target.value))}
          />
          {coffecientFeather}
        </label>

        <label>
          Cross-section area:
          <input
            type="range"
            min="0.1"
            max="5"
            step="0.1"
            value={areaFeather}
            onChange={(e) => setAreaFeather(Number(e.target.value))}
          />
          {areaFeather}
        </label>

        <button onClick={startFalling} disabled={isFallingBall || isFallingFeather}>
          Start Falling
        </button>
      </div>
    </div>
  );
}

export default SimpleSimulator;
