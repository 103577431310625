import React, {useState} from 'react';
import './App.css';
import Modal from "react-modal";

import Menu from './Components/Menu';
import SimpleSimulator from './Views/SimpleSimulator';
import AdvanceSimulator from './Views/AdvanceSimulator';


import {
  BrowserRouter as Router,
  Routes, Route

} from 'react-router-dom';



Modal.setAppElement("#root");

function App() {

  return (
    <div className="App">
        <Router>
          <div className="row heading">
              <div className="col"> <h3> <img style={{width:"40px"}} 
              src="/images/logo.jpg"/> 
              <a href="https://www.edbetter.uk/"><sub>EdBetter</sub></a>    </h3>
              <span className="tagline">Making Learning Fun</span>
              </div>
            
            </div>
            <div className="centerMenu"><Menu/></div>
          
            <Routes>
                <Route  path="/" element={<SimpleSimulator/>} />
                {/* <Route  path="/advance" element={<AdvanceSimulator/>} />   */}
            </Routes>
        </Router>
    </div>
  );
}



export default App;
