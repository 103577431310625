import React, { Component } from 'react';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import './mmenu.css';

class Menu extends Component {
    state = {  } 
    render() { 
        return  <Navbar 
         expand="sm" className='navbar-expand mybar' py-0="true">
        <Container>
          <Navbar.Brand href="/">&nbsp;</Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="me-auto">
              <Nav.Link href="/">Simple Simulator</Nav.Link>
              <Nav.Link href="/Advance">Advance Simulator</Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>;
    }
}
 
export default Menu;